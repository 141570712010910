import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Section, PageHeroSection } from "components/section";
import Layout from "components/Layout";

const SecondPage = ({ data }) => {
  let services = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <Helmet>
        <title>Our Cleaning Services - CleanWright</title>
      </Helmet>
      <PageHeroSection>
        <h1>Our Services</h1>
      </PageHeroSection>
      <Section>
        <Container>
          <Row>
            {services.map(({ node }) => (
              <Col className="mb-4" md="4" key={node.fields.slug}>
                <Card className="shadow-sm h-100">
                  {node.frontmatter.image && (
                    <Img
                      alt={node.frontmatter.title}
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                    />
                  )}
                  <CardTitle className="mt-4">
                    <h3>
                      <Link to={`/service${node.fields.slug}`}>
                        {node.frontmatter.title}
                      </Link>
                    </h3>
                  </CardTitle>
                  <CardBody className="text-center">
                    <p>{node.excerpt}</p>
                    <Link to={`/service${node.fields.slug}`}>Read More</Link>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default SecondPage;

export const query = graphql`
  {
    allMarkdownRemark(sort: { fields: frontmatter___featured, order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            featured
            image {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt(pruneLength: 250)
        }
      }
    }
  }
`;
